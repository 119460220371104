<!--
 * @Description: 富文本编辑器
 * @Author: yanxiao
 * @Github: 
 * @Date: 2021-05-25 15:09:55
 * @LastEditors: yanxiao
-->
<template>
  <div id='richText'></div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import E from 'wangeditor'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  model: {
    prop: 'value',
    event: 'changeText'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 300
    },
    uploadImgMaxLength: {
      type: Number,
      default: 100
    }
  },

  data () {
    //这里存放数据
    return {
      editor: null
    };
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.initrichText()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    initrichText () {
      let self = this
      this.editor = new E('#richText')
      this.editor.config.height = this.height

      // 配置 onchange 回调函数
      this.editor.config.onchange = function (newHtml) {
        self.$emit('changeText', newHtml)
      }
      // 配置触发 onchange 的时间频率，默认为 200ms
      this.editor.config.onchangeTimeout = 500 // 修改为 500ms

      /**  上传图片 */
      // 一次最多上传几张图片
      this.editor.config.uploadImgMaxLength = this.uploadImgMaxLength
      // 自定义上传图片
      this.editor.config.customUploadImg = function (resultFiles, insertImgFn) {
        self.$emit("uploadImg", resultFiles, insertImgFn)
        console.log(insertImgFn);
        /**
         * resultFiles 是 input 中选中的文件列表
         * insertImgFn(src, alt, href) 是获取图片 url 后，插入到编辑器的方法
         * 上传图片，返回结果，将图片插入到编辑器中
         * insertImgFn('https://cdn.freebiesupply.com/logos/large/2x/vue-9-logo-png-transparent.png')
         */
      }
      // 剔除上传视频的菜单
      this.editor.config.excludeMenus = [
        'emoticon',   // 表情
        'video'       // 视频
      ]

      this.editor.create()
      this.setValue(this.value)
    },
    setValue (value) {
      // 设置初始化内容
      this.editor.txt.html(value) // 重新设置编辑器内容
    }
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
</style>